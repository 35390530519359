import { SVGProps } from "react";

const SvgFirstAidKit = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 388.8 388.8"
        xmlSpace="preserve"
        {...props}
    >
        <path d="M221.84 209.939a8 8 0 0 1-8-8v-36.56h-38.88v36.56a8 8 0 0 1-8 8H130.4v38.8h36.56a8 8 0 0 1 8 8v36.56h38.8v-36.56a8 8 0 0 1 8-8h36.64v-38.8h-36.56z" />
        <path d="M333.6 89.459H55.2c-25.44 0-55.2 14.48-55.2 55.2v173.84c0 40.72 29.76 55.2 55.2 55.2h278.4c25.44 0 55.2-14.48 55.2-55.2v-173.84c0-40.72-29.68-55.2-55.2-55.2zm-59.2 163.36c0 6.583-5.337 11.92-11.92 11.92h-32.64v32.64c0 6.583-5.337 11.92-11.92 11.92h-47.04c-6.583 0-11.92-5.337-11.92-11.92v-32.64H126.4c-6.583.044-11.956-5.257-12-11.84v-47.04c0-6.583 5.337-11.92 11.92-11.92h32.64v-32.64c0-6.583 5.337-11.92 11.92-11.92h46.96c6.583 0 11.92 5.337 11.92 11.92v32.64h32.64c6.583-.044 11.956 5.257 12 11.84v47.04zM245.835 15.139c-.877-.05-1.757-.05-2.635 0h-97.6c-12.837-.727-23.833 9.089-24.56 21.925-.05.878-.05 1.757 0 2.635v36h16l.08-36c0-2.32 0-8.56 8.56-8.56h97.52c8.56 0 8.56 6.16 8.56 8.56v36h16v-36c.728-12.837-9.089-23.832-21.925-24.56z" />
    </svg>
);

export default SvgFirstAidKit;
