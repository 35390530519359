export interface NavbarLinkProps extends React.HTMLProps<HTMLAnchorElement> {
    variant?: "secondary" | "primary";
    active?: boolean;
    className?: string;
}

const NavLink: React.FC<NavbarLinkProps> = ({
    className,
    active,
    variant = "secondary",
    children,
    ...props
}) => {
    return (
        <a
            className={`nav-link opacity-75 p-0
				${active === false && "opacity-50"}
				${active === true && "opacity-100"}
				${variant === "secondary" ? "text-dark" : "text-primary"}
				${className ? className : ""}
			`}
            {...props}
        >
            {children}
        </a>
    );
};

export default NavLink;
