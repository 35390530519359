import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 122.879 103.609"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.368 0h102.144c5.703 0 10.367 4.665 10.367 10.367 0 5.702-4.664 10.368-10.367 10.368H10.368C4.666 20.735 0 16.07 0 10.368 0 4.665 4.666 0 10.368 0zm0 82.875h102.144c5.703 0 10.367 4.665 10.367 10.367 0 5.702-4.664 10.367-10.367 10.367H10.368C4.666 103.609 0 98.944 0 93.242c0-5.702 4.666-10.367 10.368-10.367zm0-41.437h102.144c5.703 0 10.367 4.665 10.367 10.367 0 5.702-4.664 10.368-10.367 10.368H10.368C4.666 62.173 0 57.507 0 51.805s4.666-10.367 10.368-10.367z"
        />
    </svg>
);

export default SvgComponent;
