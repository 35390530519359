import { DetailedHTMLProps, HTMLAttributes } from "react";
import { useNavbar } from "./helpers";
import NavbarToggle from "./NavbarToggle";

export interface NavbarProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
    fluid?: boolean;
}

/**
 * This component should be use only with navbar compatible components.
 * */
const Navbar: React.FC<NavbarProps> = ({
    children,
    className,
    fluid,
    ...props
}) => {
    const navbar = useNavbar();
    return (
        <nav
            data-testid="navbar"
            className={`
				shadow-sm position-sticky sticky-top p-0
				bg-light w-100 ${className}
			`}
            style={{
                height: 55,
                // boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.1)",
            }}
            {...props}
        >
            <div
                className={`container${
                    fluid ? "-fluid" : ""
                } h-100 d-flex flex-nowrap`}
            >
                {(navbar.activeDrawers ?? 0) > 0 && (
                    <NavbarToggle
                        onClick={navbar.toggleDrawer}
                        toggled={navbar.drawerExpanded}
                    />
                )}
                {children}
            </div>
        </nav>
    );
};

export default Navbar;
