import useUser from "helpers/useUser";
import React, { Fragment, ReactNode, useState } from "react";
import Business from "types/Business";

const ShortCut: React.FC<{
    children: ReactNode;
    businesses: Business[];
    url: string;
    badge?: boolean;
}> = ({ children, businesses, url, badge }) => {
    const [show, setShow] = useState<boolean>(false);
    const toggleDropdown = () => setShow((prev) => !prev);

    return (
        <div className={`${businesses.length > 1 ? "dropdown" : ""}`}>
            {businesses.length == 1 && (
                <a
                    href={url.replace("${businessId}", businesses[0].id)}
                    className="btn btn-secondary position-relative btn-sm"
                >
                    {children}
                </a>
            )}
            {businesses.length > 1 && (
                <>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown();
                        }}
                        className="btn btn-sm btn-secondary dropdown-toggle"
                        role="button"
                        aria-expanded={show}
                    >
                        {children}
                    </div>

                    <ul
                        className={`dropdown-menu  mt-1 ${show ? "show" : ""}`}
                        style={{
                            position: "absolute",
                            right: 0,
                        }}
                    >
                        {businesses.map((b) => {
                            const singleUrl = url.replace(
                                "${businessId}",
                                b.id
                            );
                            return (
                                <li key={b.id}>
                                    <a
                                        className="dropdown-item"
                                        href={singleUrl}
                                        onClick={() => setShow(false)}
                                        style={{
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                        }}
                                    >
                                        <div className="d-flex align-content-center gap-3">
                                            {b.name}
                                            {b.hasUnconfirmedAppointments &&
                                                badge && (
                                                    <span className="badge badge-danger fill-danger bg-danger h-auto my-auto">
                                                        !
                                                    </span>
                                                )}
                                        </div>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </div>
    );
};

const NavBarButtons: React.FC = () => {
    const user = useUser();
    return (
        <Fragment>
            {(user.businesses?.length ?? 0) > 0 && (
                <Fragment>
                    <ShortCut
                        businesses={user.businesses as Business[]}
                        url="/my-businesses/${businessId}/appointments?mode=nuevo turno"
                    >
                        Nuevo turno
                    </ShortCut>
                    <ShortCut
                        businesses={user.businesses as Business[]}
                        url="/my-businesses/${businessId}"
                    >
                        Administrar
                    </ShortCut>
                    <ShortCut
                        businesses={user.businesses as any[]}
                        url="/my-businesses/${businessId}/appointments?mode=agenda"
                        badge={true}
                    >
                        Agenda{" "}
                        {user.businesses &&
                            user.businesses.some(
                                (business) =>
                                    business.hasUnconfirmedAppointments === true
                            ) && (
                                <span
                                    className="badge badge-danger fill-danger bg-danger z-3 position-absolute"
                                    style={{
                                        top: -7,
                                        right: -8,
                                    }}
                                >
                                    !
                                </span>
                            )}
                    </ShortCut>
                    <ShortCut
                        businesses={user.businesses as Business[]}
                        url="/my-businesses/${businessId}/manage/timeframes"
                    >
                        Horarios
                    </ShortCut>
                </Fragment>
            )}
        </Fragment>
    );
};

export default NavBarButtons;
