import Navbar from "@tuturno/tuturno-ui/components/Navbar";
import NavLink from "@tuturno/tuturno-ui/components/Navbar/NavLink";
import NavAvatar from "@tuturno/tuturno-ui/components/Navbar/NavAvatar";
import Icon from "@tuturno/tuturno-ui/components/Icon";
import NavBrand from "@tuturno/tuturno-ui/components/Navbar/NavBrand";
import User from "types/User";
import Business from "types/Business";
import useUser from "helpers/useUser";
import Link from "next/link";
import { ReactNode } from "react";
import NavBarButtons from "./NavBarButtons";
import { usePathname } from "next/navigation";

interface HeaderProps {
    businesses?: Business[];
    user?: User | null;
    fluid?: boolean;
    className?: string;
    children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({
    children,
    className,
    fluid = true,
}) => {
    const user = useUser();
    const pathname = usePathname();
    return (
        <Navbar>
            <NavBrand>{children}</NavBrand>
            {user.loading && (
                <NavAvatar
                    icon={
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    }
                />
            )}
            {pathname && !pathname.startsWith("/register-business") && (
                <div className="d-none d-md-flex align-items-center gap-1 me-1">
                    <NavBarButtons />
                </div>
            )}
            {user.uid && (
                <NavAvatar icon={user.name?.[0]} className="p-2">
                    <div className="d-flex flex-column align-items-end justify-content-end">
                        {(user.businesses?.length ?? 0) > 0 && (
                            <div>
                                <NavLink
                                    href="/my-businesses"
                                    className="d-flex align-items-center"
                                >
                                    Mis negocios
                                    <Icon
                                        name="HomeCity"
                                        width={20}
                                        height={20}
                                        className="ms-2 fill-primary"
                                    />
                                </NavLink>
                                <div
                                    className="w-100 border-bottom border-secondary"
                                    style={{ height: "1px" }}
                                />
                            </div>
                        )}
                        <NavLink
                            href="/me"
                            className="d-flex align-items-center"
                        >
                            Mi cuenta
                            <Icon
                                name="Account"
                                width={20}
                                height={20}
                                className="ms-2 fill-primary"
                            />
                        </NavLink>
                        <NavLink
                            href="/me/appointments"
                            className="d-flex align-items-center"
                        >
                            Mis turnos
                            <Icon
                                name="Calendar"
                                width={20}
                                height={20}
                                className="ms-2 fill-primary"
                            />
                        </NavLink>
                        <NavLink
                            href="/me/security"
                            className="d-flex align-items-center"
                        >
                            Seguridad
                            <Icon
                                name="Build"
                                width={20}
                                height={20}
                                className="ms-2 fill-primary"
                            />
                        </NavLink>
                        <NavLink
                            href="/help"
                            className="d-flex align-items-center"
                        >
                            Ayuda
                            <Icon
                                name="Question"
                                width={20}
                                height={20}
                                className="ms-2 fill-primary"
                            />
                        </NavLink>
                        <button
                            className="text-dark opacity-75 border-0 nav-link p-1 bg-transparent ms-auto"
                            onClick={async () => await user.logout()}
                        >
                            Salir
                            <Icon
                                name="Login"
                                width={20}
                                height={20}
                                className="ms-2 fill-primary"
                            />
                        </button>
                        {!user.loading &&
                            (user.businesses?.length ?? 0) === 0 && (
                                <div>
                                    <NavLink
                                        href="/register-business"
                                        className="d-flex align-items-center"
                                    >
                                        ¡Sumá tu negocio!
                                        <Icon
                                            name="HomeCity"
                                            width={20}
                                            height={20}
                                            className="ms-2 fill-primary"
                                        />
                                    </NavLink>
                                </div>
                            )}
                        {pathname &&
                            !pathname.startsWith("/register-business") && (
                                <div className="d-md-none mt-2 border-top w-100 pt-2 mx-2">
                                    <div>
                                        <b>Accesos directos:</b>
                                    </div>
                                    <div className="d-flex flex-column pr-2 gap-2">
                                        <NavBarButtons />
                                    </div>
                                </div>
                            )}
                    </div>
                </NavAvatar>
            )}
            {!user.uid && !user.loading && (
                <Link
                    href="/login"
                    className="align-self-center px-4 btn btn-primary btn-sm"
                >
                    Ingresar
                </Link>
            )}
        </Navbar>
    );
};

export default Header;
