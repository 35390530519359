import { SVGProps } from "react";

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 32 32"
        {...props}
    >
        <g transform="translate(100.397 91.667)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="matrix(-1 0 0 1 -36.397 91.667)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="rotate(90 -48.032 52.365)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="matrix(0 -1 -1 0 36.333 -68.397)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="rotate(45 -80.85 245.866)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="scale(-1 1) rotate(45 -240.85 -140.408)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="scale(1 -1) rotate(45 -10.222 307.12)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
        <g transform="rotate(-135 96.786 -97.018)">
            <g transform="translate(-100.397 -83.667)">
                <path d="M1 7a1 1 0 00-1 1 1 1 0 001 1h30a1 1 0 001-1 1 1 0 00-1-1z"></path>
                <path d="M8 0a1 1 0 00-.707.293l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414 0 1 1 0 000-1.414L2.414 8l6.293-6.293a1 1 0 000-1.414A1 1 0 008 0z"></path>
            </g>
        </g>
    </svg>
);

export default SvgArrowLeft;
