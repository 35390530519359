import { DetailedHTMLProps, HTMLAttributes } from "react";

const NavBrand: React.FC<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
    return (
        <div
            className={`
				d-flex flex-nowrap align-items-center 
                flex-fill text-nowrap
				overflow-hidden
				me-1 p-0
                ${className ?? ""}
			`}
            {...props}
        />
    );
};

export default NavBrand;
