import { HTMLProps, ReactNode } from "react";
import { useNavbar } from "../helpers";

export interface NavAvatarProps extends HTMLProps<HTMLDivElement> {
    icon: ReactNode;
    className?: string;
}

const NavAvatar: React.FC<NavAvatarProps> = ({ icon, children, className }) => {
    const navbar = useNavbar();
    return (
        <div
            className={`
				d-flex align-items-center 
				justify-content-center
				flex-nowrap text-nowrap
                position-relative
			`}
        >
            <button
                data-testid="avatar-toggler"
                onClick={navbar.toggleAvatar}
                className={`
					rounded-circle p-0 m-0 ms-1 border-0
					d-flex align-items-center justify-content-center
                    bg-secondary
                    focus-color-dark
				`}
                style={{
                    width: 30,
                    height: 30,
                    minWidth: 30,
                    minHeight: 30,
                }}
            >
                {icon}
            </button>
            <div
                data-testid="avatar-dropdown"
                style={{
                    top: 55,
                    right: 0,
                    width: "100vw",
                }}
                className={`
					${navbar.avatarExpanded ? "d-block" : "d-none"}
					bg-light rounded-lg shadow-sm
                    position-fixed position-lg-absolute text-end 
                    border-top border-secondary
					vw-lg-20 mt-lg-3 border-lg-0
                    ${className}
				`}
            >
                {children}
            </div>
        </div>
    );
};

export default NavAvatar;
