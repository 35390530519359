import { SVGProps } from "react";

const SvgLost = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300.46 300.46"
        xmlSpace="preserve"
        {...props}
    >
        <path d="M130.631 116.503a5.272 5.272 0 0 0-4.42-2.398H79.26v-11.094a7.867 7.867 0 1 0-15.734 0v47.886H16.573a5.272 5.272 0 0 0-4.42 2.398l-8.457 13.003a1.549 1.549 0 0 0 0 1.689l8.457 13.003a5.272 5.272 0 0 0 4.42 2.398h46.952v101.338H48.89a7.867 7.867 0 1 0 0 15.734h45.005a7.867 7.867 0 1 0 0-15.734H79.26V146.594h46.951a5.272 5.272 0 0 0 4.42-2.398l8.457-13.003a1.549 1.549 0 0 0 0-1.689l-8.457-13.001z" />
        <circle cx={226.252} cy={40.42} r={23.08} />
        <path d="M256.05 73.565h-51.469l-24.386-2.851 13.127-15.771c3.934-4.727 3.292-11.749-1.435-15.684-4.728-3.934-11.749-3.291-15.683 1.435l-26.274 31.57a11.137 11.137 0 0 0 7.265 18.185l39.291 4.594.005 192.054c0 7.381 5.983 13.363 13.363 13.363s13.363-5.982 13.363-13.363V180.284h5.77v106.813c0 7.381 5.983 13.363 13.363 13.363s13.363-5.982 13.363-13.363l-.237-187.114a2.329 2.329 0 0 1 4.656-.086v.001l.411 81.812c.031 6.131 5.01 11.08 11.135 11.08l.057-.001c6.15-.031 11.11-5.042 11.08-11.192l-.411-81.812c-.074-14.458-11.896-26.22-26.354-26.22zM274.453 34.401h9.608v10.165h-9.608zM283.502 0c-5.687 0-10.767 2.717-13.016 7.995l7.5 5.331c.855-.995 2.018-3.843 5.083-3.843 1.868 0 3.719 1.091 3.719 3.099 0 3.496-5.852 5.85-7.624 6.818-4.459 2.316-4.897 5.458-4.897 9.112h9.608c0-1.408 1.28-2.327 2.758-3.161 4.231-2.387 10.382-4.021 10.382-12.706C297.014 4.314 290.919 0 283.502 0z" />
    </svg>
);

export default SvgLost;
