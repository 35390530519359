import { MouseEvent } from "react";

export interface NavbarTogglerProps {
    onClick?(event: MouseEvent<HTMLButtonElement>): void;
    toggled?: boolean;
}

const NavbarToggle: React.FC<NavbarTogglerProps> = ({ onClick, toggled }) => {
    return (
        <button
            type="button"
            data-testid="navbar-toggler"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={onClick}
            className={`
				d-flex flex-column align-items-center 
				justify-content-center p-0 
				my-0 ms-0 me-3 border-0
				d-lg-none bg-transparent
			`}
        >
            <span
                className={toggled ? "bg-info" : "bg-muted"}
                style={{
                    width: 20,
                    height: 3,
                    marginBottom: 4,
                    display: "block",
                    transition: "all 0.1s ease",
                    transform: toggled ? "translateY(5.5px) rotate(45deg)" : "",
                }}
            />
            <span
                className={toggled ? "bg-info" : "bg-muted"}
                style={{
                    width: 20,
                    height: 3,
                    display: toggled ? "none" : "block",
                }}
            />
            <span
                className={toggled ? "bg-info" : "bg-muted"}
                style={{
                    width: 20,
                    height: 3,
                    marginTop: 4,
                    transition: "all 0.1s ease",
                    transform: toggled
                        ? "translateY(-5.5px) rotate(-45deg)"
                        : "",
                }}
            />
        </button>
    );
};

export default NavbarToggle;
