import { SVGProps } from "react";

const SvgDirections = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 493.348"
        {...props}
    >
        <path
            fill="#fff"
            strokeWidth={20}
            d="M216.333 6h79.333v481.348h-79.333z"
        />
        <path
            fill="#fff"
            strokeWidth={20}
            d="m418.049 244.378 30.97 49.308-25.564 52.318-355.614 18.921-5.406-101.627zM94.523 51.717 62.69 100.475l24.641 52.759 355.227 25.164 7.191-101.516z"
        />
        <text x={228.682} y={334.702} transform="rotate(-3.045)">
            <tspan fontSize={70} x={228.682} y={344.702}>
                {"\n          ?\n        "}
            </tspan>
        </text>
        <path strokeWidth={20} d="M0 487.348h512" />
        <text fontSize={70} x={222.258} y={113.572} transform="rotate(4.052)">
            <tspan x={204.258} y={119.572}>
                {"\n          404\n        "}
            </tspan>
        </text>
    </svg>
);

export default SvgDirections;
