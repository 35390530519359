import { SVGProps } from "react";

const SvgMarketingCreditCard = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="96 0 32 32"
        {...props}
    >
        <g transform="matrix(.93826 0 0 .93826 32.988 -.47)">
            <path d="M16 .5A10.836 10.836 0 005.166 11.334c0 5.23-1.112 8.461-2.15 10.365-1.039 1.904-1.848 2.387-1.848 2.387A1.5 1.5 0 002 26.834h28a1.5 1.5 0 00.832-2.748s-.81-.483-1.848-2.387-2.15-5.135-2.15-10.365A10.836 10.836 0 0016 .5zm0 3a7.832 7.832 0 017.834 7.834c0 5.659 1.22 9.427 2.516 11.8.173.319.33.428.507.7H5.143c.177-.272.334-.381.507-.7 1.295-2.373 2.516-6.141 2.516-11.8A7.832 7.832 0 0116 3.5z"></path>
            <path d="M18.307 30.105a1.5 1.5 0 00-.914.698 1.608 1.608 0 01-2.198.588 1.612 1.612 0 01-.59-.588 1.5 1.5 0 00-2.048-.545 1.5 1.5 0 00-.545 2.05 4.612 4.612 0 007.976 0 1.5 1.5 0 00-.545-2.05 1.5 1.5 0 00-1.136-.153z"></path>
        </g>
        <g transform="matrix(1.03226 0 0 1.03226 95.484 3.425)">
            <path d="M4.545.5C2.328.5.5 2.328.5 4.545v15.273c0 2.217 1.828 4.045 4.045 4.045h22.91c2.217 0 4.045-1.828 4.045-4.045V4.545C31.5 2.328 29.672.5 27.455.5zm0 3h22.91c.595 0 1.045.45 1.045 1.045v15.273c0 .596-.45 1.045-1.045 1.045H4.545c-.595 0-1.045-.45-1.045-1.045V4.545c0-.595.45-1.045 1.045-1.045z"></path>
            <path d="M2 8.137a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5h28a1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5z"></path>
        </g>
        <g transform="matrix(.9697 0 0 .9697 -.485 .97)">
            <path d="M12.5.5a1.5 1.5 0 00-1.424 1.025L6.918 14H2a1.5 1.5 0 00-1.5 1.5A1.5 1.5 0 002 17h6a1.5 1.5 0 001.424-1.025L12.5 6.746l7.576 22.729a1.5 1.5 0 002.848 0L27.082 17H32a1.5 1.5 0 001.5-1.5A1.5 1.5 0 0032 14h-6a1.5 1.5 0 00-1.424 1.025L21.5 24.254 13.924 1.525A1.5 1.5 0 0012.5.5z"></path>
        </g>
        <g transform="matrix(.9143 0 0 .9143 63.543 .26)">
            <path d="M18 .5a1.5 1.5 0 00-1.346.836l-4.593 9.31-10.278 1.502a1.5 1.5 0 00-.83 2.559l7.436 7.24-1.756 10.23A1.5 1.5 0 008.81 33.76L18 28.928l9.19 4.832a1.5 1.5 0 002.177-1.582l-1.756-10.23 7.436-7.241a1.5 1.5 0 00-.83-2.559l-10.278-1.502-4.593-9.31A1.5 1.5 0 0018 .5zm0 4.889l3.6 7.29a1.5 1.5 0 001.127.821l8.048 1.178-5.822 5.672a1.5 1.5 0 00-.432 1.328l1.375 8.011-7.199-3.785a1.5 1.5 0 00-1.394 0l-7.2 3.785 1.376-8.011a1.5 1.5 0 00-.432-1.328l-5.822-5.672 8.048-1.178a1.5 1.5 0 001.127-.82z"></path>
        </g>
    </svg>
);

export default SvgMarketingCreditCard;
