import { SVGProps } from "react";

const SvgExam = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        {...props}
    >
        <path d="M316 30h-17.563C292.24 12.539 275.556 0 256 0c-19.556 0-36.24 12.539-42.437 30H196c-24.814 0-45 20.186-45 45 0 8.291 6.709 15 15 15h180c8.291 0 15-6.709 15-15 0-24.814-20.186-45-45-45z" />
        <path d="M406 60h-16.518A74.989 74.989 0 0 1 391 75c0 24.814-20.186 45-45 45H166c-24.814 0-45-20.186-45-45 0-5.136.529-10.151 1.518-15H106c-24.814 0-45 20.186-45 45v362c0 24.814 20.186 45 45 45h300c24.814 0 45-20.186 45-45V105c0-24.814-20.186-45-45-45zM211 420h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-105h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-105h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15zm145.605 214.395c5.859 5.859 5.859 15.352 0 21.211A14.952 14.952 0 0 1 346 450.001a14.95 14.95 0 0 1-10.605-4.395L316 426.211l-19.395 19.395A14.952 14.952 0 0 1 286 450.001a14.95 14.95 0 0 1-10.605-4.395c-5.859-5.859-5.859-15.352 0-21.211L294.789 405l-19.395-19.395c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0L316 383.789l19.395-19.395c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211L337.211 405l19.394 19.395zm15-143.79-45 45A14.952 14.952 0 0 1 316 330a14.95 14.95 0 0 1-10.605-4.395l-30-30c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0L316 293.789l34.395-34.395c5.859-5.859 15.352-5.859 21.211 0 5.859 5.859 5.859 15.352-.001 21.211zm0-90-45 45A14.952 14.952 0 0 1 316 240a14.95 14.95 0 0 1-10.605-4.395l-30-30c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0L316 203.789l34.395-34.395c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352-.001 21.211z" />
    </svg>
);

export default SvgExam;
