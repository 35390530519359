import * as Icons from "./icons";

export interface IconProps extends React.SVGProps<SVGSVGElement> {
    name: keyof typeof Icons;
}

const Icon: React.FC<IconProps> = ({ name, ...props }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const SelectedIcon: React.FC<any> = Icons[name];
    return <SelectedIcon {...props}/>;
};

export default Icon;
